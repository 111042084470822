<template>
    <modal class="filterModel" isMask :isShow="isShow" @click="hide()" @keyup.esc="hide">
        <div class="header">自定义过滤条件<i class="iconfont icon-a-chahao3x" @click="hide()"></i></div>
        <div class="modal-body">
            <div class="border-box" v-if="isFilter">
                <div class="title">查询条件</div>
                <div class="li-tr" v-if="filterData.FILTER_EATROOM">
                    <div class="lable-txt">营业餐厅：</div>
                    <div class="from-input">
                        <el-select v-model="roomids" multiple collapse-tags @remove-tag="roomids=[]">
                            <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="li-tr" v-if="filterData.FILTER_EATPHASE">
                    <div class="lable-txt">营业时段：</div>
                    <div class="from-input">
                        <el-select v-model="salesValue" placeholder="全天" multiple collapse-tags @remove-tag="salesValue=[]">
                            <el-option
                                v-for="item in eatPhases"
                                :key="item.EAT_PHASEID"
                                :label="item.EAT_PHASENAME"
                                :value="item.EAT_PHASEID">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="li-tr" v-if="filterData.FILTER_EATXFKIND">
                    <div class="lable-txt">菜品类别：</div>
                    <div class="from-input">
                        <select-tree v-model="categoryIDs" placeholder="请选择" :data="foodMainCategory" title="菜品类别选择"></select-tree>
                    </div>
                </div>
                <div class="li-tr" v-if="filterData.FILTER_EATXFFINKIND">
                    <div class="lable-txt">出品部门：</div>
                    <div class="from-input">
                        <select-tree v-model="departIDs" placeholder="请选择" :data="CHHDPIDListTree" title="出品部门选择"></select-tree>
                    </div>
                </div>
                <div class="li-tr" v-if="filterData.FILTER_EATCUSTOMMAS">
                    <div class="lable-txt">做法：</div>
                    <div class="from-input">
                        <el-radio-group v-model="diyMake" class="radio-group">
                            <el-radio :label="false">不包括自定义做法</el-radio>
                            <el-radio :label="true">包括自定义做法</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="border-box" v-if="filterData?.group?.length>0">
                <div class="title">分组汇总</div>
                <div class="tr-box">
                    <div class="from-input">
                        <el-select v-model="summaryType" placeholder="请选择" default-first-option>
                            <el-option :label="item.DataFilter_Name" :value="item.DataFilter_Key" v-for="item in filterData.group" :key="item"></el-option>
                        </el-select>
                        <div class="order"></div>
                    </div>
                </div>
            </div>
            <div class="border-box" v-if="filterData?.order?.length>0">
                <div class="title">排序规则</div>
                <div class="tr-box">
                    <div class="from-input">
                        <el-select v-model="orderKey" placeholder="请选择" default-first-option>
                            <el-option :label="item.DataFilter_Name" :value="item.DataFilter_Key" v-for="item in filterData.order" :key="item"></el-option>
                        </el-select>
                        <div class="order" v-if="orderType=='asc'" @click="orderType='desc'">
                            <i class="iconfont icon-a-shengxu3x"></i>
                            <div class="text">升序</div>
                        </div>
                        <div class="order" v-else  @click="orderType='asc'">
                            <i class="iconfont icon-a-jiangxu3x"></i>
                            <div class="text">降序</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="confirm()">查询</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:"filterModel",
    emits:["confirm","close"],
    props:{
        isShow:Boolean,
        //筛选 类别
        filter:Array,
        //筛选数据
        data:Object
    },
    computed:{
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        //出品部门 数据
        CHHDPIDListTree(){
            let CHHDPIDList=[];
            this.CHHDPIDList?.map(item=>{
                    let depart={
                        id:item.CHH_DEPARTKINDID,
                        name:item.CHH_DEPARTKINDNAME,
                        children:[],
                        Hierarchy:1
                    }
                    if(item.EatChhDeparts){
                        item.EatChhDeparts.map(eat=>{
                            let chieat={
                                id:eat.CHH_DEPARTID,
                                name:eat.CHH_DEPARTNAME,
                                Hierarchy:2
                            }
                            depart.children.push(chieat)
                        })
                    }
                    CHHDPIDList.push(depart)
                })
            return CHHDPIDList;
        },
        //筛选 条件 显示
        filterData(){
            let data={
                FILTER_EATROOM:false,//餐厅过滤条件
                FILTER_EATPHASE:false,//时段过滤条件
                FILTER_EATXFKIND:false,//菜品类别过滤条件
                FILTER_EATXFFINKIND:false,//出品部门过滤条件
                FILTER_EATCUSTOMMAS:false,//自定义作法
                group:[],//分组
                order:[]//排序
            }
            this.filter?.forEach((it)=>{
                if(it.DataFilter_Type==1){
                    data[it.DataFilter_Key]=true;
                }else if(it.DataFilter_Type==2){
                    data["group"].push(it);
                }else if(it.DataFilter_Type==3){
                    data["order"].push(it);
                }
            })
            return data;
        },
        /**是否有过滤条件 */
        isFilter(){
            if(this.filter?.length>0){
                return this.filter.some(it=>it.DataFilter_Type==1)
            }
            return false;
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.$nextTick(()=>{
                    this.init();
                })
            }
        }
    },
    data(){
        return {
            userInfo:null,
            /**菜品数据 */
            posFoodData:null,
            categoryIDs:[],
            //餐厅id
            roomids:[],
            //时段数据
            eatPhases:null,
            //时段
            salesValue:[],
            //出品部门 数据
            CHHDPIDList:null,
            departIDs:[],
            //默认 false 不包括自定义做法 true 包括自定义做法
            diyMake:false,
            //汇总类型
            summaryType:null,
            //排序类型 ASC 升序  DESC 降序 
            orderType:'asc',
            orderKey:null,
            //桌台数据
            PosRooms:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo();

            //时段数据
            this.$cacheData.EatPhase().then((d)=>{
                if(d){
                    this.eatPhases=d;
                }
            }).catch((e)=>{
                this.$message.error('加载时段数据失败：'+e.message);
                console.log('加载时段数据失败：'+e);
            })
            /**出品部门 */
            this.$cacheData.ChhDepart().then((d)=>{
                this.CHHDPIDList =d;
                
            }).catch((e)=>{
                this.$message.error('加载出品部门数据失败：'+e.message);
                console.log('加载出品部门数据失败：'+e);
            })

            //菜品数据结构
            this.$cacheData.PosFoods().then((d)=>{
                this.posFoodData=d;
            }).catch(e=>{
                this.$message.error('加载菜品数据失败：'+e.message);
                console.log('加载菜品数据失败：'+e);
            })

            this.$cacheData.EatRooms().then((d)=>{
                this.PosRooms=d;
            }).catch((e)=>{
                this.$message.error('加载桌台数据加载失败：'+e.message);
            });
            this.init();
        })
    },
    methods:{
        init(){
            //默认分组
            this.filterData.group?.find(it=>{
                if(it.Is_Group){
                    this.summaryType=it.DataFilter_Key;
                    return true;
                }   
                return false;
            })
            //默认排序
            this.filterData.order?.find(it=>{
                if(it.Is_Orderby){
                    this.orderKey=it.DataFilter_Key;
                    this.orderType=it.Is_Desc?"desc":"asc";
                    return true;
                }   
                return false;
            })
            this.diyMake=true;
            this.data?.filter?.forEach((it)=>{
                if(it.key=="FILTER_EATROOM"){//餐厅
                    this.roomids=it.value;
                }else if(it.key=="FILTER_EATPHASE"){//时段
                    this.salesValue=it.value;
                }else if(it.key=="FILTER_EATXFKIND"){//菜品类别
                    this.categoryIDs=it.value;
                }else if(it.key=="FILTER_EATXFFINKIND"){//出品部门
                    this.departIDs=it.value;
                }else if(it.key=="FILTER_EATCUSTOMMAS"){//自定义做法
                    this.diyMake=it.value;
                }
            })
            //分组
            if(this.data?.group){
                this.summaryType=this.data?.group;
            }
            //排序
            if(this.data?.order){
                this.orderKey=this.data?.order.key;
                this.orderType=this.data?.order.Is_Desc?"desc":"asc";
            }
        },
        hide(){
            this.$emit("close");
        },
        //点击查询
        confirm(){
            let data={
                filter:[],
                group:this.summaryType,
                order:{
                    key:this.orderKey,
                    Is_Desc:this.orderType=="desc"
                }
            }
            if(this.roomids?.length>0){
                data.filter.push({key:"FILTER_EATROOM",value:this.roomids});
            }
            if(this.salesValue?.length>0){
                data.filter.push({key:"FILTER_EATPHASE",value:this.salesValue});
            }
            if(this.categoryIDs?.length>0){
                data.filter.push({key:"FILTER_EATXFKIND",value:this.categoryIDs});
            }
            if(this.departIDs?.length>0){
                data.filter.push({key:"FILTER_EATXFFINKIND",value:this.departIDs});
            }

            if(this.filterData.FILTER_EATCUSTOMMAS && this.diyMake===false){//自定义做法
                data.filter.push({key:"FILTER_EATCUSTOMMAS",value:this.diyMake});
            }

            this.$emit("confirm",data);
        }
    }
}
</script>

<style lang="scss">
@import './filterModel.scss'
</style>